body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(233, 250, 255);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


html, body{
  height:100%;
}

#root{
  height: 100%;
  margin:0;
  padding: 0;
  overflow: hidden;
}



.border.border-grey{
  border: 1px solid #e6e6e6;
}



.fill-empty-inputbox {
 
  margin-top: 30px auto;
  width: 80%;
  text-align: center;
  border: none;
  font-size: 36px;
  border-bottom: 2px solid black;
  color: rgb(0, 80, 47);
  background-color: transparent;
  
}

input:focus.fill-empty-inputbox{
  outline: none;
}

ul
{
  list-style-type: none;
}


